import React, { useContext, useEffect, useState } from 'react'
import ModalWindow from '../../common/component/ModalWindow'
import KnotesTossPaymentsWidget from './KnotesTossPaymentsWidget'
import PaidAmount from '../domain/PaidAmount'
import KnotesProduct from '../domain/KnotesProduct'
import { getAllAvaliableProducts } from '../repository/KnotesProductRepository'
import { activateFreeTrial, createEmptyOrder } from '../repository/KnotesOrderRepository'
import { useNavigate } from 'react-router-dom'
import { Context as UserContext } from '../../user/provider/UserInfoProvider'
import KnotesOrder from '../domain/KnotesOrder'
import materialSrc from '../../styles/assets/image/material-active.svg'
import topicTreeSrc from '../../styles/assets/image/tree-active.svg'
import articleSrc from '../../styles/assets/image/article-active.svg'
import KnotesPricingPolicy from '../component/KnotesPolicy'
import { userInfoRepository } from '../../user/repository/UserInfoRepository'

const KnotesPricing = () => {
  const navigate = useNavigate()
  const { userInfo } = useContext(UserContext)!
  const [policyModalOpen, setPolicyModalOpen] = useState<boolean>(false)
  const [tossPaymentModalOpen, setTossPaymentTossPaymentModalOpen] = useState<boolean>(false)
  const [paidAmount, setPaidAmount] = useState<PaidAmount>({
    currency: 'KRW',
    value: 0,
  })
  const [order, setOrder] = useState<KnotesOrder>({
    orderId: '',
    productName: '',
  })
  const [products, setProducts] = useState<KnotesProduct[]>([])
  const [selectedProduct, setSelectedProduct] = useState<KnotesProduct | null>(null)

  useEffect(() => {
    getAllAvaliableProducts().then((response) => {
      response.sort((product) => product.price)
      setProducts(response)
    })
  }, [])

  const processPayment = (productId: number, productName: string, price: number) => {
    if (price === 0) {
      activateFreeTrial().then(() => {
        navigate('/main')
        window.location.reload()
      })
    } else {
      createEmptyOrder(productId).then((response) => {
        setOrder({
          orderId: response.orderId,
          productName: productName,
        })
        setPaidAmount({
          currency: 'KRW',
          value: price,
        })
        setTossPaymentTossPaymentModalOpen(true)
      })
    }
  }

  return (
    <main>
      <ModalWindow isOpen={policyModalOpen} closeModal={() => setPolicyModalOpen(false)}>
        <KnotesPricingPolicy
          onConfirm={(marketingAgreement) => {
            void userInfoRepository.updateKnotesMarketingAgreement(marketingAgreement)
            setPolicyModalOpen(false)
            if (selectedProduct) {
              processPayment(selectedProduct.id, selectedProduct.name, selectedProduct.price)
            }
          }}
        />
      </ModalWindow>
      <ModalWindow
        isOpen={tossPaymentModalOpen}
        closeModal={() => setTossPaymentTossPaymentModalOpen(false)}
      >
        <KnotesTossPaymentsWidget
          paidAmount={paidAmount}
          order={order}
          email={userInfo?.email ?? ''}
        />
      </ModalWindow>
      <div className="knotes-pricing container">
        <h1>K-NOTES</h1>
        <h2>수천만 편의 논문을 이용한 자료 수집 및 편리한 논문 초록 작성</h2>
        <div className="knotes-features">
          <div className="feature">
            <img src={materialSrc} alt="프로젝트" />
            프로젝트
          </div>
          <div className="feature">
            <img src={topicTreeSrc} alt="토픽트리" />
            토픽트리
          </div>
          <div className="feature">
            <img src={articleSrc} alt="아티클" />
            아티클
          </div>
          <div className="feature">
            <img src={materialSrc} alt="자료" />
            자료
          </div>
        </div>
        <div className="knotes-hook">
          <p>밥 한끼 값으로 1년 동안 이용할 수 있어요.</p>
          <p>
            케이노츠 서비스는 정기 결제(자동결제)로 제공되며, 자유롭게 자동 결제 해지가 가능합니다.
          </p>
        </div>
        <div className="pricing-plans">
          {products.map((product) => (
            <div className="plan" key={product.id}>
              <div className="name">{product.name}</div>
              <div className="pricing">
                <span>{product.uiHighlighted}</span> {product.uiNormal}
              </div>
              <button
                className={product.price > 0 ? 'paid' : ''}
                onClick={() => {
                  setPolicyModalOpen(true)
                  setSelectedProduct(product)
                }}
              >
                신청하기
              </button>
            </div>
          ))}
        </div>
        <div className="warning">
          <p>주의사항</p>
          <ul>
            <li>결제 후 즉시 케이노츠를 이용할 수 있습니다.</li>
            <li>
              결제 후 7일 이내에 최소할 수 있습니다. (결제 취소는 마이페이지의 이용권 정보 화면을
              통해 취소)
            </li>
            <li>환불은 구독 만료 일자로부터 30일 전까지만 가능합니다.</li>
            <li>
              환불 요청시 구매한 구독권의 10%의 취소 수수료를 차감하고, 남은 기간에 대해 일할
              계산하여 환불됩니다.
            </li>
          </ul>
        </div>
      </div>
    </main>
  )
}

export default KnotesPricing
