import React, { useContext } from 'react'
import CategorySelector from './CategorySelector'
import { FilterOptionSelector } from './FilterOptionSelector'
import ArticleCountSummary from '../domain/ArticleCountSummary'
import Category from '../domain/Category'
import { Context as SearchConditionContext } from '../provider/SearchConditionProvider'
import SearchCounts from '../domain/SearchCounts'

interface Props {
  isLoading: boolean
  topLevelCategories: Map<number, Category>
  searchCounts: SearchCounts
}

const SearchFilters = ({ isLoading, topLevelCategories, searchCounts }: Props) => {
  const searchCondition = useContext(SearchConditionContext)!

  const applyFilterConditions = () => {
    searchCondition.setManualSearchTrigger(!searchCondition.manualSearchTrigger)
  }

  const clearAllFilterConditionsAndApply = () => {
    searchCondition.setCategoryIds([])
    searchCondition.setJournals(new Map())
    searchCondition.setAuthors(new Map())
    searchCondition.setPublishYears(new Map())
    searchCondition.setKeywords(new Map())
    searchCondition.setSources(new Map())

    searchCondition.setManualSearchTrigger(!searchCondition.manualSearchTrigger)
  }

  const validOptionValueExists = (options: ArticleCountSummary<string | number>[]) => {
    return options.filter((option) => option.value.trim() !== '').length > 0
  }

  return (
    <>
      <aside aria-label="articles-category" className="aside">
        <nav className="lnb">
          <CategorySelector
            topLevelCategories={topLevelCategories}
            setSelectedCategories={searchCondition.setCategoryIds}
            selectedIds={searchCondition.categoryIds}
            isLoading={isLoading}
          />
          {validOptionValueExists(searchCounts.journals) && (
            <FilterOptionSelector
              title={'저널별'}
              options={searchCounts.journals.map((journal) => {
                const converted: ArticleCountSummary<string> = {
                  key: journal.key.toString(),
                  value: journal.value,
                  count: journal.count,
                }
                return converted
              })}
              setSelectedOptions={(selectedOptions: Map<string, string>) => {
                const selectedValueMap = new Map(
                  Array.from(selectedOptions, ([key, value]) => [parseInt(key, 10), value])
                )
                searchCondition.setJournals(selectedValueMap)
              }}
              selectedIds={Array.from(searchCondition.journals.keys()).map((x) => x.toString())}
              isLoading={isLoading}
            />
          )}

          {validOptionValueExists(searchCounts.authors) && (
            <FilterOptionSelector
              title={'저자별'}
              options={searchCounts.authors.map((author) => {
                const converted: ArticleCountSummary<string> = {
                  key: author.key.toString(),
                  value: author.value,
                  count: author.count,
                }
                return converted
              })}
              setSelectedOptions={(selectedOptions: Map<string, string>) => {
                const selectedValueMap = new Map(
                  Array.from(selectedOptions, ([key, value]) => [parseInt(key, 10), value])
                )
                searchCondition.setAuthors(selectedValueMap)
              }}
              selectedIds={Array.from(searchCondition.authors.keys()).map((x) => x.toString())}
              isLoading={isLoading}
            />
          )}

          {validOptionValueExists(searchCounts.publishYears) && (
            <FilterOptionSelector
              title={'연도별'}
              options={searchCounts.publishYears.sort((a, b) => b.key.localeCompare(a.key))}
              setSelectedOptions={searchCondition.setPublishYears}
              selectedIds={Array.from(searchCondition.publishYears.keys())}
              isLoading={isLoading}
            />
          )}

          {validOptionValueExists(searchCounts.keywords) && (
            <FilterOptionSelector
              title={'주제별'}
              options={searchCounts.keywords}
              setSelectedOptions={searchCondition.setKeywords}
              selectedIds={Array.from(searchCondition.keywords.keys())}
              isLoading={isLoading}
            />
          )}

          {validOptionValueExists(searchCounts.sources) && (
            <FilterOptionSelector
              title={'출처별'}
              options={searchCounts.sources}
              setSelectedOptions={searchCondition.setSources}
              selectedIds={Array.from(searchCondition.sources.keys())}
              isLoading={isLoading}
            />
          )}
        </nav>
      </aside>
      <div className="filter-btn">
        <button type="button" className="execute" onClick={() => applyFilterConditions()}>
          검색필터 적용
        </button>
        <button
          type="button"
          className="reset"
          onClick={() => clearAllFilterConditionsAndApply()}
        />
      </div>
    </>
  )
}

export default SearchFilters
