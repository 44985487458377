import { useState } from 'react'
import SecondRequirement from './SecondRequirement'
import OptionalRequirement from './OptionalRequirement'
import FirstRequirement from './FirstRequirement'

interface Props {
  onConfirm: (marketingAgreement: boolean) => void
}

const KnotesPolicy = ({ onConfirm }: Props) => {
  const [firstRequired, setFirstRequired] = useState<boolean>(false)
  const [secondRequired, setSecondRequired] = useState<boolean>(false)
  const [optionalMarketingPolicy, setOptionalMarketingPolicy] = useState<boolean>(false)

  const [openFirstRequirement, setOpenFirstRequirement] = useState<boolean>(false)
  const [openSecondRequirement, setOpenSecondRequirement] = useState<boolean>(false)
  const [openOptionalRequirement, setOpenOptionalRequirement] = useState<boolean>(false)

  const checkAllAgree = (checked: boolean) => {
    setFirstRequired(checked)
    setSecondRequired(checked)
    setOptionalMarketingPolicy(checked)
  }

  const isAllAgree = firstRequired && secondRequired && optionalMarketingPolicy
  const isAllRequirementsAgreed = firstRequired && secondRequired

  return (
    <div aria-label="knotes-policy-modal" className="knotes-policy-modal">
      <div className="title">케이노츠 서비스 약관에 동의해주세요</div>
      <label className="checkbox" htmlFor="allAgree">
        <input
          type="checkbox"
          id="allAgree"
          checked={isAllAgree}
          onChange={(e) => checkAllAgree(e.target.checked)}
        />
        <span>모두 동의합니다.</span>
      </label>
      <div className="policy-description" aria-label="first-requirement">
        <div className="policy-check-container">
          <label className="checkbox" htmlFor="firstRequired">
            <input
              type="checkbox"
              id="firstRequired"
              checked={firstRequired}
              onChange={(e) => setFirstRequired(e.target.checked)}
            />
            <span>(필수) 서비스 이용 약관 동의</span>
          </label>
          {openFirstRequirement ? (
            <button className="detail-button" onClick={() => setOpenFirstRequirement(false)}>
              닫기
            </button>
          ) : (
            <button className="detail-button" onClick={() => setOpenFirstRequirement(true)}>
              전체보기
            </button>
          )}
        </div>
        {openFirstRequirement && <FirstRequirement />}
      </div>
      <div className="policy-description" aria-label="second-requirement">
        <div className="policy-check-container">
          <label className="checkbox" htmlFor="secondRequired">
            <input
              type="checkbox"
              id="secondRequired"
              checked={secondRequired}
              onChange={(e) => setSecondRequired(e.target.checked)}
            />
            <span>(필수) 개인정보 수집 및 이용 동의</span>
          </label>
          {openSecondRequirement ? (
            <button className="detail-button" onClick={() => setOpenSecondRequirement(false)}>
              닫기
            </button>
          ) : (
            <button className="detail-button" onClick={() => setOpenSecondRequirement(true)}>
              전체보기
            </button>
          )}
        </div>
        {openSecondRequirement && <SecondRequirement />}
      </div>
      <div className="policy-description" aria-label="optional-requirement">
        <div className="policy-check-container">
          <label className="checkbox" htmlFor="optional">
            <input
              type="checkbox"
              id="optional"
              checked={optionalMarketingPolicy}
              onChange={(e) => setOptionalMarketingPolicy(e.target.checked)}
            />
            <span>(선택) 홍보 이메일 수신 동의</span>
          </label>
          {openOptionalRequirement ? (
            <button className="detail-button" onClick={() => setOpenOptionalRequirement(false)}>
              닫기
            </button>
          ) : (
            <button className="detail-button" onClick={() => setOpenOptionalRequirement(true)}>
              전체보기
            </button>
          )}
        </div>
        {openOptionalRequirement && <OptionalRequirement />}
      </div>
      <div className="caution">카드 결제만 가능합니다.</div>
      <button
        className="confirm-button"
        disabled={!isAllRequirementsAgreed}
        onClick={() => onConfirm(optionalMarketingPolicy)}
      >
        이용권 구매 및 결제하기
      </button>
    </div>
  )
}

export default KnotesPolicy
