import React, { useEffect, useState } from 'react'
import { loadTossPayments, TossPaymentsWidgets } from '@tosspayments/tosspayments-sdk'
import PaidAmount from '../domain/PaidAmount'
import KnotesOrder from '../domain/KnotesOrder'

const clientKey = 'test_gck_docs_Ovk5rk1EwkEbP0W43n07xlzm'

const KnotesTossPaymentsWidget = ({
  paidAmount,
  order,
  email,
}: {
  paidAmount: PaidAmount
  order: KnotesOrder
  email: string
}) => {
  const [ready, setReady] = useState<boolean>(false)
  const [widgets, setWidgets] = useState<TossPaymentsWidgets | null>(null)

  useEffect(() => {
    async function fetchPaymentWidgets() {
      // ------  결제위젯 초기화 ------
      const tossPayments = await loadTossPayments(clientKey)
      const customerKey = email
      // 회원 결제
      const widgets = tossPayments.widgets({
        customerKey,
      })
      // 비회원 결제
      // const widgets = tossPayments.widgets({ customerKey: ANONYMOUS });

      setWidgets(widgets)
    }

    fetchPaymentWidgets()
  }, [email])

  useEffect(() => {
    async function renderPaymentWidgets() {
      if (widgets == null) {
        return
      }
      // ------ 주문의 결제 금액 설정 ------
      await widgets.setAmount(paidAmount)

      await Promise.all([
        // ------  결제 UI 렌더링 ------
        widgets.renderPaymentMethods({
          selector: '#payment-method',
          variantKey: 'DEFAULT',
        }),
        // ------  이용약관 UI 렌더링 ------
        widgets.renderAgreement({
          selector: '#agreement',
          variantKey: 'AGREEMENT',
        }),
      ])

      setReady(true)
    }

    renderPaymentWidgets()
  }, [widgets, paidAmount])

  useEffect(() => {
    if (widgets == null) {
      return
    }

    widgets.setAmount(paidAmount)
  }, [widgets, paidAmount])

  return (
    <div className="toss-payments-modal" aria-label="toss-payments-modal">
      <div className="wrapper">
        <h2 className="payment-summary">{`토스페이먼츠에서 ${paidAmount.value}원을 결제합니다.`}</h2>
        <div className="box_section">
          {/* 결제 UI */}
          <div id="payment-method" />
          {/* 이용약관 UI */}
          <div id="agreement" />
          {/* 결제하기 버튼 */}
          <button
            className="payment-button"
            disabled={!ready}
            onClick={async () => {
              try {
                // ------ '결제하기' 버튼 누르면 결제창 띄우기 ------
                // 결제를 요청하기 전에 orderId, amount를 서버에 저장하세요.
                // 결제 과정에서 악의적으로 결제 금액이 바뀌는 것을 확인하는 용도입니다.
                const successUrl = new URL(window.location.origin + '/knotes/order/success')
                const failUrl = new URL(window.location.origin + '/knotes/order/failure')
                successUrl.searchParams.set('orderId', order.orderId)
                successUrl.searchParams.set('paidAmount', paidAmount.value.toString())
                await widgets?.requestPayment({
                  orderId: order.orderId,
                  orderName: order.productName,
                  successUrl: successUrl.toString(),
                  failUrl: failUrl.toString(),
                  customerEmail: email,
                })
              } catch (error) {
                // 에러 처리하기
                console.error(error)
              }
            }}
          >
            결제하기
          </button>
        </div>
      </div>
    </div>
  )
}

export default KnotesTossPaymentsWidget
