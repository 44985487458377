import React, { useEffect } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { processPayment } from '../repository/KnotesOrderRepository'

const KnotesOrderSuccess = () => {
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  useEffect(() => {
    processPayment(
      searchParams.get('orderId')!,
      searchParams.get('paymentKey')!,
      searchParams.get('paidAmount')!
    ).then(() => {
      navigate('/main')
      window.location.reload()
    })
  }, [navigate, searchParams])
  return (
    <main>
      <div className="container">
        <div>주문에 성공했습니다.</div>
      </div>
    </main>
  )
}

export default KnotesOrderSuccess
