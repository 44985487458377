const SecondRequirement = () => {
  return (
    <div className="requirement">
      <p>
        이용자는 로그인해야 케이노츠를 사용할 수 있습니다. 회사는 기본적인 회원제 서비스를 제공하기
        위해 이메일 이외에는 개인정보를 저장하지 않습니다.
      </p>
      <p>
        회사 정책의 변경, 서비스 변경에 대한 사항은 홈페이지의 공지와 이메을을 통해서만 제공합니다.
        따라서 이메일을 수신하지 않음으로써 발생하는 회원의 불이익에 대해 회사는 책임을 지지
        않습니다.
      </p>
      <p>1.수집 목적</p>
      서비스 이용에 따른 민원사항의 처리를 위한 본인식별
      <br />
      식별, 고지사항 전달, 본인 의사 확인, 불만처리 등 의사소통 경로 확보, 새로운 알림 서비스 제공
      <br />
      <p>
        2. 보유 및 이용기간
        <br />
        <b>- 회원탈퇴 또는 동의철회 시 지체없이 파기하거나 법령에 따른 보존기간 이후 파기</b>
        <br />
        <b>- 부정이용기록은 회원탈퇴 1년 보관 후 파기</b>
      </p>
      <br />
      <p>
        위 개인정보 수집 및 이용 동의에 대해 거부할 권리가 있습니다. 그러나 동의를 거부할 경우에는
        서비스를 이용할 수 없습니다.
      </p>
    </div>
  )
}

export default SecondRequirement
