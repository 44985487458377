import React, { Fragment, useContext, useEffect, useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'
import RingLoader from 'react-spinners/RingLoader'

import ModalWindow from '../../common/component/ModalWindow'
import Location from '../../common/component/Location'
import Button from '../../common/component/Button'
import { removeDuplicatesFromArrays } from '../../common/utils/DuplicationRemover'
import { isNotEmpty } from '../../common/utils/TextUtils'

import MyArticlesStorageBox from '../../user/component/MyArticlesStorageBox'
import { Context as UserContext } from '../../user/provider/UserInfoProvider'
import { userStorageRepository } from '../../user/repository/UserStorageRepository'

import Article, { getPublishingInfo } from '../domain/Article'
import AuthorArticles from '../domain/AuthorArticles'
import { Context as CategoryContext } from '../provider/CategoryProvider'
import { articleRepository } from '../repository/ArticleRepository'
import OtherArticlesForAuthors from '../component/OtherArticlesForAuthors'
import HelmetArticleDetailMeta from '../component/HelmetArticleDetailMeta'

function ArticleDetail() {
  const { id } = useParams()

  const [modalOpen, setModalOpen] = useState<boolean>(false)
  const { isLoggedIn } = useContext(UserContext)!
  const { categoryPaths } = useContext(CategoryContext)!
  const [article, setArticle] = useState<Article>()
  const [authorArticles, setAuthorArticles] = useState<AuthorArticles[]>([])

  const openArticlesSaveModal = () => {
    if (!isLoggedIn) {
      global.alert('로그인이 필요합니다.')
      return
    }
    setModalOpen(true)
  }

  const successAddArticlesHandler = () => {
    alert('학술논문이 선택하신 보관함에 저장되었습니다.')
    setModalOpen(false)
  }

  const selectStorage = (storageId: number) => {
    if (article?.id) {
      userStorageRepository.addArticles(storageId, [article.id]).then(successAddArticlesHandler)
    }
  }

  const getArticleInformation = async (articleId: string) => {
    const article: Article = await articleRepository.getArticle(articleId)
    const authorIds = article.authors.filter((author) => author.id > 0).map((author) => author.id)
    const otherArticles = await articleRepository.getOtherArticlesByAuthors(article.id, authorIds)
    setArticle(article)
    setAuthorArticles(otherArticles)
  }

  const articleInfoComponent = (
    checkField: string | null | undefined,
    title: string,
    pContent: string | null | undefined,
    subContent: string | null | undefined,
    nextLineSubContent: string | null | undefined,
    link: string | null | undefined
  ) => {
    if (checkField && checkField.trim() !== '') {
      return (
        <dl>
          <dt>{title}</dt>
          <dd>
            {
              <p>
                {pContent && pContent.trim() !== '' && pContent}
                {subContent && subContent.trim() !== '' && (
                  <span className="sub">{subContent}</span>
                )}
                {link && link.trim() !== '' && <a href={link}>{link}</a>}
              </p>
            }
            {nextLineSubContent && nextLineSubContent.trim() !== '' && (
              <p>
                <span className="sub">{nextLineSubContent}</span>
              </p>
            )}
          </dd>
        </dl>
      )
    }
    return <></>
  }

  const otherArticlesForAuthors = useMemo(() => {
    if (article && article.authors.find((author) => author.id > 0) !== undefined) {
      const validAuthors = article.authors.filter((author) => author.id > 0)
      return <OtherArticlesForAuthors authors={validAuthors} authorArticles={authorArticles} />
    }
    return <Fragment />
  }, [article, authorArticles])

  useEffect(() => {
    if (id) {
      getArticleInformation(id)
    }
  }, [id])

  const paths = useMemo(
    () => [...(categoryPaths ?? []).filter((item) => item !== 'ROOT')],
    [categoryPaths]
  )

  return (
    <main>
      <HelmetArticleDetailMeta article={article} />
      <ModalWindow isOpen={modalOpen} closeModal={() => setModalOpen(false)}>
        <MyArticlesStorageBox isModifiable={false} selectStorage={selectStorage} />
      </ModalWindow>
      <div className="container">
        {article ? (
          <>
            <Location paths={[...paths, article.articleSource.journal]} />
            <div className="content-wrap">
              <section className="content">
                <div className="result-summary above">
                  <div className="func">
                    <Button
                      ariaLabel="storage-save-button"
                      className="keep"
                      onClick={openArticlesSaveModal}
                    >
                      보관함에 저장
                    </Button>
                  </div>
                </div>
                <article className="thesis-detail">
                  <hgroup>
                    <h1 dangerouslySetInnerHTML={{ __html: article.title }}></h1>
                    <h2>{article.subTitle}</h2>
                  </hgroup>
                  <div className="info box">
                    {articleInfoComponent(
                      article.articleSource.contentId,
                      '출처',
                      article.articleSource.contentId.toLowerCase() === 'koreapatent'
                        ? '특허'
                        : article.articleSource.contentId,
                      article.articleSource.contentId.toLowerCase() === 'koreapatent'
                        ? ` ${article.articleSource.volumeIssue}`
                        : null,
                      null,
                      null
                    )}
                    {articleInfoComponent(
                      article.articleType,
                      '유형',
                      article.articleType?.toUpperCase(),
                      null,
                      null,
                      null
                    )}
                    {articleInfoComponent(
                      article.articleSource.journal,
                      '저널',
                      `《${article.articleSource.journal}》 ${article.articleSource.publisher}`,
                      getPublishingInfo(article),
                      null,
                      null
                    )}
                    {articleInfoComponent(article.doi, 'DOI', null, null, null, article.doi)}
                    {articleInfoComponent(
                      article.authors.map((author) => author.getAuthorName()).join(', '),
                      '저자',
                      article.authors.map((author) => author.getAuthorName()).join(', '),
                      null,
                      null,
                      null
                    )}
                    {isNotEmpty(article.abstractsContent && article.abstractsContent.trim()) && (
                      <dl>
                        <dt>초록</dt>
                        <dd>
                          {isNotEmpty(article.abstractsTitle) && (
                            <p className="abstracts-title">{article.abstractsTitle}</p>
                          )}
                          {isNotEmpty(article.abstractsContent) && (
                            <p dangerouslySetInnerHTML={{ __html: article.abstractsContent }}></p>
                          )}
                          {isNotEmpty(article.subAbstractsTitle) && (
                            <p className="abstracts-title">{article.subAbstractsTitle}</p>
                          )}
                          {isNotEmpty(article.subAbstractsContent) && (
                            <p>
                              <span
                                className="sub"
                                dangerouslySetInnerHTML={{ __html: article.subAbstractsContent! }}
                              ></span>
                            </p>
                          )}
                        </dd>
                      </dl>
                    )}
                    {((article.keywords &&
                      article.keywords.filter((k) => k.trim() !== '').length > 0) ||
                      (article.subKeywords &&
                        article.subKeywords.filter((k) => k.trim() !== '').length > 0)) && (
                      <dl>
                        <dt>키워드</dt>
                        <dd>
                          <ul className="keyword">
                            {Array.from(
                              removeDuplicatesFromArrays(article.keywords, article.subKeywords)
                            ).map((keyword, index) => (
                              <li
                                key={`${keyword}-${index}`}
                                dangerouslySetInnerHTML={{ __html: keyword }}
                              />
                            ))}
                          </ul>
                        </dd>
                      </dl>
                    )}
                    {article.patentClaims && article.patentClaims.length > 0 && (
                      <>
                        <dl>
                          <dt>청구항</dt>
                          <dd>
                            {article.patentClaims.map((claim) => (
                              <p>
                                {claim.claimNumber} : {claim.claimContent}
                              </p>
                            ))}
                          </dd>
                        </dl>
                      </>
                    )}
                  </div>
                  <div className="func box">
                    <a href={article.articleUrl} target="_brank" className="website">
                      사이트로 이동
                    </a>
                    {article.otherUrls &&
                      article.otherUrls.length > 0 &&
                      article.otherUrls.map((otherUrl) => (
                        <a href={otherUrl.url} target="_brank" className="website">
                          다른 사이트({otherUrl.domain})
                        </a>
                      ))}
                    <a href="/#" className="bookmark">
                      북마크
                    </a>
                  </div>
                </article>
              </section>
              {otherArticlesForAuthors}
            </div>
          </>
        ) : (
          <div>
            <RingLoader size={30} color="#C63DEE" />
            <p style={{ marginTop: '10px' }} className="sum">
              로딩중입니다...
            </p>
          </div>
        )}
      </div>
    </main>
  )
}

export default ArticleDetail
