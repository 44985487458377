const OptionalRequirement = () => {
  return (
    <div className="requirement">
      <p>
        회사는 기본적인 회원제 서비스 외의 부가 서비스를 제공하기 위해 다음과 같이 선택적인
        개인정보를 수집하고 이용합니다. 내용을 확인하시고 동의하여 주십시오.
      </p>
      <br />
      <p>
        <b>1. 수집하는 항목</b>
        <br />
        <b>- 이메일</b>
      </p>
      <br />
      <p>
        <b>2. 수집 목적</b>
        <br />
        - 이벤트 정보 및 참여 기회 제공, 광고성 정보 제공, 타깃 마케팅
        <br />- <span>홍보 및 마케팅 등을 위하여 정보 주체에게 연락</span>
      </p>
      <br />
      <p>
        <b>3. 보유 및 이용기간</b>
        <br />
        <b>- 회원탈퇴 또는 동의철회 시 지체없이 파기하거나 법령에 따른 보존기간 이후 파기</b>
        <br />
        <br />
      </p>
      <p>
        위 개인정보 수집 및 이용 동의에 대해 거부할 권리가 있습니다. 그러나 동의를 거부할 경우에는
        일부 서비스 안내를 받을 수 없습니다.
      </p>
    </div>
  )
}

export default OptionalRequirement
