import React from 'react'

const KnotesOrderFailure = () => {
  return (
    <main>
      <div className="container">
        <div>주문에 실패했습니다. 관리자에게 문의해주세요.</div>
      </div>
    </main>
  )
}

export default KnotesOrderFailure
