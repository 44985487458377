import Article from '../domain/Article'
import { Helmet } from 'react-helmet-async'

type Props = {
  article?: Article
}

export const ArticleDetailMeta = ({ article }: Props) => {
  if (!article) {
    return <></>
  }
  return (
    <>
      <title>{article.title} - Kdiscovery</title>
      <link rel="canonical" href={`https://kdiscovery.kr/articles/${article.id}`} />
      <meta name="dc.title" content={article.title} />
      <meta name="dc.source" content={article.articleSource.journal} />
      <meta name="dc.publisher" content={article.articleSource.publisher} />
      <meta name="dc.type" content={article.articleType?.toUpperCase()} />
      <meta name="dc.identifier" content={article.doi.replace('https://', '')} />
      <meta name="dc.subject" content={article.categoryNames.join(' ')} />
      <meta name="prism.publicationName" content={article.articleSource.journal} />
      <meta name="prism.url" content={`https://kdiscovery.kr/articles/${article.id}`} />
      <meta name="prism.doi" content={article.doi.replace('https://', '')} />
      <meta name="citation_journal_title" content={article.articleSource.journal} />
      <meta name="citation_publisher" content={article.articleSource.publisher} />
      <meta name="citation_title" content={article.title} />
      <meta name="citation_article_type" content={article.articleType?.toUpperCase()} />
      <meta
        name="citation_doi"
        content={article.doi.replace('https://', '').replace('doi.org/', '')}
      />
      <meta name="description" content={article.abstractsContent} />
    </>
  )
}

const HelmetArticleDetailMeta = ({ article }: Props) => (
  <Helmet>{ArticleDetailMeta({ article })}</Helmet>
)

export default HelmetArticleDetailMeta
