import { useNavigate } from 'react-router-dom'
import KDiscoveryTerms from '../../user/component/KDiscoveryTerms'
import PrivateTerms from '../../user/component/PrivateTerms'
import React, { useState } from 'react'

const Footer = () => {
  const [openKDiscoveryTerms, setOpenKDiscoveryTerms] = useState(false)
  const [openPrivacyTerms, setOpenPrivacyTerms] = useState(false)
  const navigate = useNavigate()
  const goToKDiscoveryIntroduction = () => {
    navigate('/introduction')
  }
  const goToFaqs = () => {
    navigate('/bbs/faqs')
  }
  return (
    <>
      <footer>
        <h1>K·DISCOVERY</h1>
        <p>
          <span>이지메타(주)</span>
          <span>대표이사: 윤택기</span>
          <span>사업자등록번호 507-87-00026</span>
          <span>통신판매업신고번호 제2020-서울구로-1171호</span>
        </p>
        <p>
          <span>07281 서울특별시 구로구 디지털로26길 111</span>
          <span>Tel. 02-584-3489</span>
          <span>E-mail. ezmeta@naver.com</span>
        </p>
        <ul className="menu">
          <li>
            <a href="http://www.ezmeta.co.kr">회사 소개</a>
          </li>
          <li>
            <a href="#!" onClick={goToKDiscoveryIntroduction}>
              K-Discovery 소개
            </a>
          </li>
          <li>
            <a href="#!" onClick={() => setOpenKDiscoveryTerms(true)}>
              이용약관
            </a>
          </li>
          <li>
            <a href="#!" onClick={() => setOpenPrivacyTerms(true)}>
              개인정보처리방침
            </a>
          </li>
          <li>
            <a href="#!" onClick={goToFaqs}>
              FAQ
            </a>
          </li>
        </ul>
        <p className="cr">Copyright ⓒ Ezmeta Inc. 2018~2024</p>
      </footer>
      <KDiscoveryTerms isOpen={openKDiscoveryTerms} close={() => setOpenKDiscoveryTerms(false)} />
      <PrivateTerms isOpen={openPrivacyTerms} close={() => setOpenPrivacyTerms(false)} />
    </>
  )
}

export default Footer
