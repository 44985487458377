import axios from 'axios'
import KnotesOrderId from '../domain/KnotesOrderId'

export const activateFreeTrial = async (): Promise<void> => {
  await axios.post('/app/api/knotes/order/free-trial')
}

export const createEmptyOrder = async (productId: number): Promise<KnotesOrderId> => {
  const { data } = await axios.post(
    '/app/api/knotes/order',
    { productId },
    {
      headers: {
        'Content-Type': 'application/json',
      },
    }
  )
  return data
}

export const processPayment = async (
  orderId: string,
  paymentKey: string,
  paidAmount: string
): Promise<void> => {
  await axios.post(
    '/app/api/knotes/order/payment',
    { orderId, paymentKey, paidAmount },
    {
      headers: {
        'Content-Type': 'application/json',
      },
    }
  )
}
