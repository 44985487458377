import React from 'react'
import { Navigate, Outlet, Route, Routes } from 'react-router-dom'

import AdvancedSearchModalProvider from './common/provider/AdvancedSearchModalProvider'
import SearchConditionProvider from './article/provider/SearchConditionProvider'
import UserInfoProvider from './user/provider/UserInfoProvider'
import CategoryProvider from './article/provider/CategoryProvider'
import { HelmetProvider } from 'react-helmet-async'

import { isTactoEnabled } from './common/utils/FeatureFlag'
import { isEditable, isKnotesUser } from './common/utils/RouteRules'
import Layout from './common/component/Layout'
import PrivateRouter from './common/component/PrivateRouter'

import Main from './main/page/Main'
import Articles from './article/page/Articles'
import ArticleDetail from './article/page/ArticleDetail'
import Authors from './author/page/Authors'
import AuthorDetail from './author/page/AuthorDetail'
import Journals from './journal/page/Journals'
import JournalArticles from './journal/page/JournalArticles'
import Publishers from './publisher/page/Publishers'
import BbsBoard from './information/page/BbsBoard'
import BbsEditor from './information/page/BbsEditor'
import BbsItemDetail from './information/page/BbsItemDetail'
import Introduction from './information/page/Introduction'
import ServiceInformation from './information/page/ServiceInformation'
import FindPassword from './user/page/FindPassword'
import ResetPassword from './user/page/ResetPassword'
import JoinForm from './user/page/JoinForm'
import JoinIntro from './user/page/JoinIntro'
import JoinRequested from './user/page/JoinRequested'
import Login from './user/page/Login'
import UserInfo from './user/page/UserInfo'
import UserInfoPassword from './user/page/UserInfoPassword'
import UserStorage from './user/page/UserStorage'
import ArticleSearch from './article/page/ArticleSearch'
import TrendIssue from './trend/page/TrendIssue'
import JoinCompleted from './user/page/JoinCompleted'
import TactoWorkspace from './tacto/page/TactoWorkspace'
import TactoProject from './tacto/page/TactoProject'
import TactoProjectProvider from './tacto/provider/TactoProjectProvider'
import TactoArticle from './tacto/page/TactoArticle'
import KnotesPricing from './tacto/page/KnotesPricing'
import KnotesOrderSuccess from './tacto/page/KnotesOrderSuccess'
import KnotesOrderFailure from './tacto/page/KnotesOrderFailure'

function App() {
  return (
    <HelmetProvider>
      <UserInfoProvider>
        <CategoryProvider>
          <SearchConditionProvider>
            <AdvancedSearchModalProvider>
              <Routes>
                <Route element={<Layout />}>
                  <Route path="/*" element={<Navigate replace to="/main" />} />
                  <Route index={true} path="/main" element={<Main />} />
                  <Route path="/articles" element={<Articles />} />
                  <Route path="/articles/:id" element={<ArticleDetail />} />
                  <Route path="/article-search" element={<ArticleSearch />} />
                  <Route path="/authors" element={<Authors />} />
                  <Route path="/authors/:id" element={<AuthorDetail />} />
                  <Route path="/journals" element={<Journals />} />
                  <Route path="/journals/:id" element={<JournalArticles />} />
                  <Route path="/publishers" element={<Publishers />} />
                  <Route path="/trend-issue" element={<TrendIssue />} />
                  <Route element={<PrivateRouter />}>
                    <Route path="/user-info" element={<UserInfo />} />
                    <Route path="/user-info/password" element={<UserInfoPassword />} />
                    <Route path="/user-info/storages" element={<UserStorage />}>
                      <Route path=":id" element={<Outlet />} />
                    </Route>
                    <Route path="/knotes/pricing" element={<KnotesPricing />} />
                    {isTactoEnabled() && (
                      <Route
                        element={
                          <PrivateRouter accessRule={isKnotesUser} redirectPath="/knotes/pricing" />
                        }
                      >
                        <Route path="/knotes" element={<TactoWorkspace />} />
                        <Route
                          path="/knotes/projects/:id"
                          element={
                            <TactoProjectProvider>
                              <TactoProject />
                            </TactoProjectProvider>
                          }
                        />
                        <Route
                          path="/knotes/projects/:projectId/articles"
                          element={<TactoArticle />}
                        />
                        <Route
                          path="/knotes/projects/:projectId/articles/:articleId"
                          element={<TactoArticle />}
                        />
                      </Route>
                    )}
                  </Route>
                  <Route path="/knotes/order/success" element={<KnotesOrderSuccess />} />
                  <Route path="/knotes/order/failure" element={<KnotesOrderFailure />} />
                  <Route path="/information/:type" element={<ServiceInformation />} />
                  <Route path="/introduction" element={<Introduction />} />
                  <Route path="/bbs/:type" element={<BbsBoard />} />
                  <Route path="/bbs/:type/:id" element={<BbsItemDetail />} />
                  <Route
                    element={<PrivateRouter accessRule={isEditable} redirectPath="/introduction" />}
                  >
                    <Route path="/bbs/edit/:type" element={<BbsEditor />} />
                  </Route>
                </Route>
                <Route>
                  <Route path="/login" element={<Login />} />
                  <Route path="/password" element={<FindPassword />} />
                  <Route path="/password/reset" element={<ResetPassword />} />
                  <Route path="/join/intro" element={<JoinIntro />} />
                  <Route path="/join/form" element={<JoinForm />} />
                  <Route path="/join/completed" element={<JoinCompleted />} />
                  <Route path="/join/requested" element={<JoinRequested />} />
                </Route>
              </Routes>
            </AdvancedSearchModalProvider>
          </SearchConditionProvider>
        </CategoryProvider>
      </UserInfoProvider>
    </HelmetProvider>
  )
}

export default App
