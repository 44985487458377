import UpdateUserInfoRequest from '../domain/request/UpdateUserInfoRequest'
import ChangePasswordRequest from '../domain/request/PasswordChangeRequest'
import CreateArticlesStorageRequest from '../domain/request/CreateArticlesStorageRequest'
import ModifyArticlesStorageRequest from '../domain/request/ModifyArticlesStorageRequest'

import AxiosUserInfoRepository from './AxiosUserInfoRepository'
import UserInfo from '../domain/UserInfo'

export default interface UserInfoRepository {
  getLoggedInUserInfo(): Promise<UserInfo>

  updateUserInfo(request: UpdateUserInfoRequest): Promise<void>

  deleteUserInfo(): Promise<void>

  changeUserPassword(request: ChangePasswordRequest): Promise<void>

  createArticlesStorage(request: CreateArticlesStorageRequest): Promise<void>

  deleteArticlesStorage(storageId: number): Promise<void>

  updateArticlesStorage(request: ModifyArticlesStorageRequest): Promise<void>

  updateKnotesMarketingAgreement(agreement: boolean): Promise<void>
}

export const userInfoRepository = new AxiosUserInfoRepository()
