import axios from 'axios'

import Page from '../../common/domain/Page'

import Article from '../domain/Article'
import ArticleAuthor from '../domain/ArticleAuthor'
import AuthorArticles from '../domain/AuthorArticles'
import ArticleRepository, { ArticlesParams } from './ArticleRepository'
import ArticleSearchResponse from '../domain/ArticleSearchResponse'
import ArticleCategoryResponse from '../domain/ArticleCategoryResponse'
import ArticleJournalResponse from '../domain/ArticleJournalResponse'
import PageRequest from '../../common/domain/PageRequest'

class AxiosArticleRepository implements ArticleRepository {
  async getArticlesInCategory(
    params: ArticlesParams,
    pageRequest: PageRequest
  ): Promise<ArticleCategoryResponse> {
    const { data } = await axios.get('/app/api/articles', {
      params: {
        ...params,
        ...pageRequest,
      },
    })
    return {
      categoryCounts: data.categoryCounts,
      articles: this.createArticlesPage(data.articles),
    }
  }

  async getArticle(id: number | string | undefined) {
    const { data } = await axios.get(`/app/api/articles/${id}`)
    return {
      ...data,
      authors: data.authors.map(
        (author: ArticleAuthor) =>
          new ArticleAuthor(author.id, author.name, author.subName, author.authorSequence)
      ),
    }
  }

  async getOtherArticlesByAuthors(
    excludeArticleId: number,
    authorIds: number[]
  ): Promise<AuthorArticles[]> {
    const { data } = await axios.get(`/app/api/articles/${excludeArticleId}/others-for-authors`, {
      params: { authorIds: authorIds.join(',') },
    })
    return data
  }

  async getArticlesInJournal(
    journalId: number,
    publishYears: string[],
    pageRequest: PageRequest
  ): Promise<ArticleJournalResponse> {
    const { data } = await axios.get(`/app/api/articles/journals/${journalId}`, {
      params: {
        publishYears: publishYears.map((value) => encodeURIComponent(value)).join(','),
        ...pageRequest,
      },
    })
    return {
      articles: this.createArticlesPage(data.articles),
      publishYearCounts: data.publishYearCounts,
    }
  }

  async searchInArticles(
    searchText: string,
    categoryIds: number[],
    journalIds: number[],
    authorIds: number[],
    publishYears: string[],
    keywords: string[],
    sources: string[],
    pageRequest: PageRequest
  ): Promise<ArticleSearchResponse> {
    const encodedSearchText = encodeURIComponent(searchText)
    window.dataLayer.push({
      event: 'search',
      search_text: searchText,
    })
    const { data } = await axios.get('/app/api/articles/search', {
      params: {
        searchText: encodedSearchText,
        categoryIds: categoryIds.join(','),
        journalIds: journalIds.join(','),
        authorIds: authorIds.join(','),
        publishYears: publishYears.map((value) => encodeURIComponent(value)).join(','),
        keywords: keywords.map((value) => encodeURIComponent(value)).join(','),
        sources: sources.map((value) => encodeURIComponent(value)).join(','),
        ...pageRequest,
      },
    })
    return {
      categoryCounts: data.categoryCounts,
      journalCounts: data.journalCounts,
      authorCounts: data.authorCounts,
      publishYearCounts: data.publishYearCounts,
      keywordCounts: data.keywordCounts,
      sourceCounts: data.sourceCounts,
      articles: this.createArticlesPage(data.articles),
      errorMessage: data.errorMessage,
    }
  }

  private createArticlesPage(data: Page<Article>): Page<Article> {
    return {
      totalPages: data.totalPages,
      totalElements: data.totalElements,
      size: data.size,
      pageable: data.pageable,
      content: data.content.map((article: Article) => {
        article.authors = article.authors.map(
          (author) =>
            new ArticleAuthor(author.id, author.name, author.subName, author.authorSequence)
        )
        return article
      }),
    }
  }
}

export default AxiosArticleRepository
