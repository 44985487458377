const FirstRequirement = () => {
  return (
    <div className="requirement">
      <p style={{ fontWeight: 'bold' }}>
        <strong>제1조 (목적)</strong>
      </p>
      <p>
        이 약관은 이지메타㈜(이하 “회사”로 약칭)가 운영하는 케이노츠(https://kdiscovery.kr/knotes/)
        사이트(이하 “사이트”로 약칭)에서 제공하는 서비스의 이용과 관련하여 “회사”와 이용자의 권리,
        의무, 책임사항을 규정함을 목적으로 합니다.
      </p>
      <p style={{ fontWeight: 'bold' }}>
        <strong>제2조 (용어의 정의)</strong>
      </p>
      <p>이 약관에서 사용하는 용어의 정의는 다음과 같습니다.</p>
      <p>1. ‘이용자’라 함은 이 약관에 따라 회사가 제공하는 서비스를 이용하는 회원을 말합니다.</p>
      <p>
        2. ‘회원’이라 함은 케이디스커버리 회원에 가입한 후 케이노츠에서 이용권을 구매한 자를
        말합니다.
      </p>
      <p>3. ‘이용계약’이라 함은 이 약관에 동의하고 서비스의 회원으로 가입하는 행위를 말합니다.</p>
      <p>
        4. ‘비밀번호’라 함은 이용자ID로 식별되는 회원의 본인 여부를 검증하기 위하여 회원이 설정하여
        회사에 등록한 고유의 문자 또는 숫자를 말합니다.
      </p>
      <p>5. ‘해지’라 함은 회사 또는 회원이 이용계약을 해약하는 것을 말합니다.</p>
      <p>
        6. ‘유료 서비스’라 함은 케이노츠 서비스 정기결제를 이용하여 비용을 지급한 후에 일정 기간
        동안 사용할 수 있는 케이노츠 서비스를 의미합니다. 세부내용은 마이페이지 및 결제 페이지에
        안내된 내용을 따릅니다.{' '}
      </p>
      <p>
        7. ‘결제’라 함은 회원이 서비스를 이용하기 위해 지불수단을 통하여 회사가 정한 금액을 지불한
        것을 의미합니다.
      </p>
      <p style={{ fontWeight: 'bold' }}>
        <strong>제3조 (약관의 효력과 변경)</strong>
      </p>
      <p>① 본 약관은 서비스를 이용하고자 하는 모든 회원에 대하여 그 효력을 발생합니다.</p>
      <p>
        ② 본 약관은 회원이 서비스 가입 시 열람 할 수 있으며, 회사는 회원이 원할 때 언제든지 약관을
        열람 할 수 있도록 회사 홈페이지 또는 앱 내에 게시합니다.
      </p>
      <p>
        ③ 회사는 『약관의 규제에 관한 법률』, 『전자상거래 등에서의 소비자보호에 관한 법률』,
        『정보통신망 이용촉진 및 정보보호 등에 관한 법률(이하 “정보통신망법”이라 합니다)』,
        『소비자기본법』, 『전자문서 및 전자거래 기본법』 등 관련법에 위배되지 않는 범위 내에서 본
        약관을 개정할 수 있습니다.
      </p>
      <p>
        ④ 회사가 약관을 변경할 경우에는 적용일자, 변경사유를 명시하여 적용일로부터 7일 이전부터
        공지합니다. 다만, 회원에게 불리한 약관 변경인 경우에는 그 적용일로부터 30일전부터 위와 같은
        방법으로 공지하고, E-mail로 회원에게 개별 통지합니다. 이 경우 E-mail 주소 변경으로 인하여
        개별 통지가 어려운 때에는 공지를 개별 통지로 간주합니다.
      </p>
      <p>
        ⑤ 회사가 개정약관을 공지 또는 통지하였음에도, 회원이 명시적으로 약관 변경에 대한 거부의사를
        표시하지 아니하는 경우 개정약관에 동의한 것으로 간주합니다.
      </p>
      <p>
        ⑥회원은 변경된 약관에 동의하지 아니하는 경우 서비스의 이용을 중단하고 이용계약을 해지할 수
        있습니다.
      </p>
      <p>
        ⑦ 회원은 약관 변경 사항을 숙지하도록 주의의무를 다하여야 하며, 변경된 약관의 부지로 인한
        회원의 피해는 회사가 책임지지 않습니다.
      </p>
      <p style={{ fontWeight: 'bold' }}>
        <strong>제4조 (약관의 해석과 예외 준칙)</strong>
      </p>
      <p>
        회사는 제공하는 개별 서비스에 대해서 별도의 이용약관 및 정책을 둘 수 있으며, 해당 내용이 이
        약관과 상충할 경우 개별 서비스의 이용약관을 우선하여 적용합니다.
      </p>
      <p style={{ fontWeight: 'bold' }}>
        <strong>제5조 (이용계약의 성립)</strong>
      </p>
      <p>
        이용계약은 약관의 내용에 동의하고, 이용권을 구입함으로써 성립됩니다. 이용자가 제공한
        개인정보는 관련법 및 "회사"의 개인정보처리방침에 따라 철저히 보호됩니다. 다만, "회사"의 공식
        사이트 이외의 링크된 사이트에서는 "회사"의 개인정보처리방침이 적용되지 않습니다.
      </p>
      <p style={{ fontWeight: 'bold' }}>
        <strong>제6조 (이용권 구매)</strong>
      </p>
      <p>
        ① 회원은 사이트에서 이용권을 구매함으로써 서비스를 이용할 수 있습니다. 비회원은 이용권을
        구매할 수 없으며, 먼저 회원 가입을 하여야 합니다.
      </p>
      <p>
        ② 회사가 기술적 사유나 기타 사정에 의하여 서비스를 제공할 수 없는 경우에는 즉시 해당
        회원에게 이메일, 공지사항 등 하나 이상의 방법으로 공지합니다.
      </p>
      <p>③ 이용권을 구매하면 회원은 마이페이지에서 이용권 구매의 내역을 확인할 수 있습니다.</p>
      <p>
        ④ 유료서비스 이용 도중, 정기 결제 갱신을 중단할 경우에는 이용계약 만료 시점까지 서비스
        이용이 가능합니다.
      </p>
      <p style={{ fontWeight: 'bold' }}>
        <strong>제7조 (정기 이용 서비스)</strong>
      </p>
      <p>
        ① 회사는 6개월 이용권과 1년 이용권의 2가지 정기 이용 서비스를 제공하며, 회사의 사정, 기타
        여건에 따라 서비스 내용을 추가하거나 변경할 수 있습니다.
      </p>
      <p>
        1. 6개월 이용 서비스: 회원이 선택한 결제 서비스 속성으로 6개월 단위로 이용요금이 정기
        결제되고, 6개월 마다 자동 갱신 결제되는 서비스
      </p>
      <p>
        2. 1년 이용 서비스: 회원이 선택한 결제 서비스 속성으로 1년 단위로 이용요금이 정기 결제되고,
        1년 마다 자동 갱신 결제되는 서비스
      </p>
      <p>
        ② 결제일은 최초 결제일과 동일한 날짜에 이루어집니다. 단 그 달의 29일 이후에 결제한 회원의
        다음 갱신월이 2월일 경우 2월의 갱신일은 28일이 됩니다. 그 달의 31일에 결제한 회원의 다음
        생신월이 30일로 끝날 경우 갱신일은 30일이 됩니다.
      </p>
      <p>
        ③ 정기 결제 갱신을 중단하고자 할 경우, 서비스 이용 기간 종료 하루 전까지 서비스 이용을
        해지해야 합니다.
      </p>
      <p>
        ④ 유료회원이 유/무선 네트워크를 통하여 서비스에 접속하거나 유/무선 네트워크가 연결된 상태의
        기기내에 탑재된 어플리케이션을 통하여 제반 서비스를 이용하는 경우, 유료회원이 가입한 해당
        통신사간에 체결된 통신 요금제에 따라 과금/청구/수납되므로, 데이터 통화료에 대해서는 회사는
        어떠한 책임도 지지 않습니다.
      </p>
      <p>
        ⑤ 회사는 서비스 요금 및 정기 이용 방식을 변경할 수 있으며, 변경 내용은 회원에게 이메일과
        공지 사항을 통해 고지합니다.{' '}
      </p>
      <p style={{ fontWeight: 'bold' }}>
        <strong>제8조 (서비스의 해제•해지)</strong>
      </p>
      <p>
        ① 유료 회원은 홈페이지 등을 통해 회사가 인정한 방법으로 서비스 이용의사를 철회하거나 서비스
        이용을 종료할 수 있습니다.
      </p>
      <p>
        ② 정기 이용 서비스를 이용 중인 유료회원이 이용요금의 지급을 연체하는 경우 연체가 발생한 날에
        자동으로 구매계약이 해지될 수 있습니다.
      </p>
      <p>
        ③ 정기결제형 유료서비스의 이용기간 중 유료회원이 사이트를 탈퇴하는 경우 이 약관상의 회원의
        권리는 장래에 향하여 모두 소멸하고 회복할 수 없으며, 회사에 대하여 남은 기간에 상당하는
        이용요금의 반환을 요구할 수 없습니다.
      </p>
      <p>
        ④ 유료회원은 회사의 귀책사유가 없는 경우에는 결제일로부터 7일 이내에 구매계약을 해제하거나
        해지할 수 있습니다. 이 경우 회사는 결제 수단에 따라 결제수수료 등의 부대비용을 공제한 후
        환불할 수 있습니다.
      </p>
      <p>
        ⑤ 회사가 제5항에 의하여 환불조치를 완료한 경우 당해 회원에게 입금되었는지 여부와 상관없이
        당해 회원의 정기결제형 유료서비스 이용 권한은 소멸합니다.
      </p>
      <p style={{ fontWeight: 'bold' }}>
        <strong>제9조 (결제 취소 &amp; 환불)</strong>
      </p>
      <p>
        ① 회사와 이용권 계약을 체결한 회원은 7일 이내에 결제에 대한 취소 및 환불을 요구할 수
        있습니다. 환불은 서비스를 이용한 일수를 제외하고 일할 계산으로 진행됩니다. 6개월 이용권의
        경우 180일 기준이고, 1년 이용권의 경우 365일 기준으로 일할 계산합니다.
      </p>
      <p>
        ② 회사의 귀책사유로 결제 오류가 발생한 경우와 회사의 귀책사유로 서비스가 중단되는 경우
        환불을 요구할 수 있습니다.
      </p>
      <p style={{ fontWeight: 'bold' }}>
        <strong>제10조 (회사의 의무)</strong>
      </p>
      <p>① 회사는 특별한 사정이 없는 한 회원이 신청한 서비스를 이용할 수 있도록 합니다.</p>
      <p>
        ② 회사는 이 약관에서 정한 바에 따라 지속적, 안정적으로 서비스를 제공하기 위해 노력해야 하며,
        부득이한 이유로 서비스가 중단되면 지체 없이 이를 수리 복구합니다.
      </p>
      <p>
        ③ 천재지변, 비상사태, 정보통신설비의 보수, 점검, 교체 및 고장, 통신두절, 제3자의 고의나
        과실에 의한 화재, 그 밖의 부득이한 사유가 발생한 경우에는 그 서비스를 일시 중단하거나 중지할
        수 있습니다.
      </p>
      <p style={{ fontWeight: 'bold' }}>
        <strong>제11조 (회원의 의무)</strong>
      </p>
      <p>① 이용자ID와 비밀번호에 관한 모든 관리의 책임은 회원에게 있습니다.</p>
      <p>② 회원은 자신의 이용자ID를 타인에게 양도, 증여할 수 없습니다.</p>
      <p>
        ③ 회원은 서비스의 이용권한, 기타 서비스 이용계약상의 지위를 타인에게 양도, 증여할 수 없으며,
        이를 담보로 제공할 수 없습니다.
      </p>
      <p>
        ④ 자신의 이용자ID가 부정하게 사용된 경우, 회원은 반드시 회사에 그 사실을 전자우편이나 기타
        방법을 이용해 통보해야 합니다.
      </p>
      <p>⑤ 회원은 이 약관 및 관계법령에서 규정한 사항을 준수하여야 합니다.</p>
      <p style={{ fontWeight: 'bold' }}>
        <strong>제12조 (면책)</strong>
      </p>
      <p>
        회사는 다음 각 호의 사유로 인하여 회원 또는 제삼자에게 발생한 손해에 대하여는 그 책임을 지지
        아니합니다.
      </p>
      <p>1. 제23조 제3항에 의하여 회사가 서비스를 제공할 수 없는 경우</p>
      <p>2. 회원이 자신의 아이디 또는 비밀번호 등의 관리를 소홀히 한 경우</p>
      <p>3. 회사의 관리영역이 아닌 공중통신선로의 장애로 서비스이용이 불가능한 경우</p>
      <p>4. 기타 회사의 귀책사유가 없는 통신서비스 등의 장애로 인한 경우</p>
      <p style={{ fontWeight: 'bold' }}>
        <strong>제13조 (회원의 게시물)</strong>
      </p>
      <p>
        ① 회사는 회원의 게시물에 대해 어떠한 법적 책임도 지지 않으며, 그에 대한 책임은 회원 개인에게
        있습니다.
      </p>
      <p>
        ② 타인의 컴퓨터를 손상시킬 수 있는 바이러스, 오염된 파일, 또는 기타 유사한 소프트웨어 또는
        프로그램을 포함하는 자료는 회사가 삭제할 수 있습니다.
      </p>
      <p style={{ fontWeight: 'bold' }}>
        <strong>제14조 (정보의 제공)</strong>
      </p>
      <p>
        ① 회사는 다음 각 호에 해당하는 경우 회원에게 필요한 정보를 E-mail로 전달할 수 있으며, 회원은
        이를 원하지 않을 경우 회원가입 절차와 회사가 제공하는 수단으로 수신을 거부할 수 있습니다.
      </p>
      <p>
        1. 회사는 회원이 서비스 이용에 필요하다고 인정되는 다양한 정보를 회원이 제공한 E-mail로
        제공할 수 있습니다.
      </p>
      <p>
        2. 회사는 서비스 운영을 위해 회원정보를 활용하여 영리목적의 광고성 E-mail을 전송할 수
        있습니다.
      </p>
      <p>
        ② 회사는 다음 각 호에 해당하는 경우 회원의 수신 거부 여부와 상관없이 E-mail을 발송할 수
        있습니다
      </p>
      <p>
        1. 이용 신청에서 입력한 전자우편 주소의 소유를 확인하기 위해서 인증 메일을 발송하는 경우
      </p>
      <p>2. 회원의 정보가 변경되어 확인하기 위해서 인증 메일을 발송하는 경우</p>
      <p>
        3. 기타 서비스를 제공함에 있어 회원이 반드시 알아야 하는 중대한 정보라고 회사가 판단하는
        경우
      </p>
      <p style={{ fontWeight: 'bold' }}>제15조 (일반 사항)</p>
      <p>
        이 약관은 대한민국 관련 법률의 적용을 받습니다. 본 약관에 명시하지 않은 사항은 전자문서 및
        전자거래기본법, 전자서명법 및 기타 관련 법령의 규정과 상관례에 따릅니다.
      </p>
      <p style={{ fontWeight: 'bold' }}>(부칙)</p>
      <p>이 약관은 2025년 03월 01일부터 적용됩니다.</p>
      <p>&nbsp;</p>
    </div>
  )
}

export default FirstRequirement
